.access-indiana-card {
  border: 1px solid $colors-cccccc;
  border-radius: 1rem;
  padding: 25px;

  .bds-aa-button {

    @media screen and (max-width: $screen-breaks-md) {
      width: 100%;
    }

    @media screen and (min-width: $screen-breaks-md) {
      width: 100%;
    }
  }

  .bds-aa-logo {
    @media screen and (max-width: $screen-breaks-md) {
      width: 5%;
    }

    @media screen and (min-width: $screen-breaks-md) {
      width: 50%;
    }
  }

  .feature-panel {
    display: flex;
    flex-direction: column;

    .panel-cell {
      flex: 1 1 0;
      font-size: 1rem;

      .heading {
        font-weight: $font-weight-bold;
        font-size: 1.25rem;
      }
    }
  }
}