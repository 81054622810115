

$screen-breaks-sm: 576px;
$screen-breaks-md: 768px;
$screen-breaks-lg: 992px;
$screen-breaks-xl: 1200px;
$screen-breaks-xxl: 1400px;


$colors-888888: #888888;
$colors-b0b0b0: #b0b0b0;
$colors-cccccc: #cccccc;
$colors-d3d3d3: #d3d3d3;
$colors-efefef: #efefef;
$colors-ffffff: #ffffff;
$colors-000000: #000000;
$colors-002800: #002800;
$colors-ac3911: #ac3911;
$colors-e95420: #e95420;
$colors-ff0000: #ff0000;
$colors-fff0eb: #fff0eb;
$colors-ffe920: #ffe920;
$colors-1a3769: #1a3769;

$font-weight-thin: 100;  
$font-weight-extra-light: 200;  
$font-weight-light: 300;  
$font-weight-normal: 400;  
$font-weight-medium: 500;  
$font-weight-semi-bold: 600;  
$font-weight-bold: 700;  
$font-weight-extra-bold: 800;  

$base-font-size: 16px;
$base-font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$base-background-color: $colors-efefef;
