.bds-checkbox {
  display: flex;
  align-items: center;

  label {
    padding: .4rem;
    font-size: 1rem;
  }

  .form-check-input {
    flex: none;
  }
}

.bds-checkbox input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    padding:0;
    outline: none;
    cursor: pointer;
  }

  input[type="checkbox"]:checked {
    background-color: #e95420;
    position: relative;
  }

  input[type="checkbox"]:checked::before {
    content: "\2713";
    font-size: 1.8em;
    font-weight: bold;
    color: white;
    position: absolute;
    top: -.4em;
  }

  .bds-checkbox input[type="checkbox"]:disabled {
    border-color: #e95420;
    background-color: #e95420;
  }
  
  .bds-checkbox input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
  }

  .bds-checkbox input[type="checkbox"]:focus {
    box-shadow: 0px 0px 5px #e95420;
  }

