html {
  font-size: $base-font-size;
  font-family: $base-font-family;
}

body {
  background-color: $base-background-color;
}

p {
  font-size: 1rem;
}

a.btn, a.btn-sm {
  font-size: 0.875rem !important;
}

button.btn, label.btn {

  @media screen and (max-width: $screen-breaks-md) {
    width: 100%;
  }

  &.bds-btn-primary, &.bds-btn-primary:active {
    background-color: $colors-e95420;
    border-color: $colors-e95420;
    color: $colors-ffffff;
  }

  &.bds-btn-secondary, &.bds-btn-secondary:active {
    background-color: $colors-fff0eb;
    border-color: $colors-e95420;
    color: $colors-e95420;

    &:disabled {
      background-color: $colors-b0b0b0;
      border-color: $colors-888888;
      color: $colors-ffffff;
    }
  }

  .spinner-border {
    color: $colors-ffffff;
    height: 1rem;
    width: 1rem;
    border-width: 2px;
  }
}

.start-with-breaking-space {
  &::before {
    content: '\00a0';
  }
}

.end-with-breaking-space {
  &::after {
    content: '\00a0';
  }
}

.start-with-spaces-1 {
  &::before {
    content: '\00a0';
  }
}

.start-with-spaces-2 {
  &::before {
    content: '\00a0\00a0';
  }
}

.start-with-spaces-3 {
  &::before {
    content: '\00a0\00a0\00a0';
  }
}

.end-with-spaces-1 {
  &::after {
    content: '\00a0';
  }
}

.end-with-spaces-2 {
  &::after {
    content: '\00a0\00a0';
  }
}

.end-with-spaces-3 {
  &::after {
    content: '\00a0\00a0\00a0';
  }
}

ul.bds-item-list {
  margin-left: 40px;
  font-size: 1rem;

  li {
    font-size: 1rem;
  }
}

.text-center-column {
  justify-content: center;
}

.progress.bds-progress-bar {
  height: 18px;
  background-color: $colors-ffffff;

  .progress-bar {
    background-color: $colors-e95420;
  }
}

.mandatory {
  &::after {
    content: '\00a0*';
    color: $colors-ff0000;
  }
}

.bds-btn-responsive {
  width: 100%;
}

.bds-divider {
  width: 100%;
  size: 1px;
  color: $colors-cccccc;
  opacity: 1.0;
  margin: 0;
}

.bds-section-heading {
  font-size: 1.25rem;
  font-weight: $font-weight-medium;
}

.bds-section-details {
  font-size: 1rem;
}

.bds-step-progress {
  .step-details {
      font-size: 1rem;
      font-weight: $font-weight-bold;
  }
}

.bds-button-group {
  .bds-btn-radio+label>div.btn {
    background-color: $colors-e95420;
    border-color: $colors-e95420;
    color: $colors-ffffff;
  }

  .bds-btn-radio:checked+label>div.btn {
    border-color: $colors-000000;
  }
}

.bds-form-error-message {
  color: #ff0000;
  font-size: 0.8rem;
}

a.bds-anchor {
  color: $colors-e95420;
  font-weight: $font-weight-medium;
  cursor: pointer;
}

/* Form classes */

.bds-outline-none {
  &:focus {
    outline: none;
  }
}

.bds-alert-modal {
  min-height: 500px;
}

.bds-confirm-modal {
  min-height: 500px;
}

.bds-modal-form-text {
  @media screen and (min-width: $screen-breaks-lg) {
    &.bds-modal-email {
      width: 400px;
    }

    &.bds-modal-pin {
      width: 200px;
    }
  }
}



.bds-form-text {
  @media screen and (min-width: $screen-breaks-lg) {
    &.bds-grid-form {
      width: 400px;
    }
  }

  @media screen and (min-width: $screen-breaks-md) {
    &.bds-section-email {
      width: 500px;
    }

    &.bds-section-dob {
      width: 200px;
    }

    &.bds-section-age {
      width: 250px;
    }
  }
}

.bds-form-select {
  @media screen and (min-width: $screen-breaks-lg) {
    &.bds-grid-form {
      width: 400px;
    }

    &.bds-section-select {
      width: 550px;
    }
  }

  @media screen and (min-width: $screen-breaks-md) {
    &.bds-ssn {
      width: 225px;
    }
  }
}

.bds-textarea {
  min-height: 300px;
}


