.bds-button-group {
  .bds-btn-radio {
    display: none;
  }

  .bds-btn-radio+label {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .bds-btn-radio+label>div.btn {
    width: 100%;
  }

  .bds-btn-radio:checked+label>div.btn {
    border: 2px solid #0b0049;
  }
}