.bds-dashboard-section {
    max-width: 1200px;
    
    label {
        font-size: 1rem;
        font-weight: $font-weight-medium;
    }

    .section-heading {
        font-size: 1.25rem;
        font-weight: $font-weight-medium;
    }
    
    .section-sub-heading {
        font-size: 1.15rem;
        font-weight: $font-weight-medium;
    }
    
}

.bds-btn-dashboard-action {
    width: 100px;
}