.main-header-container {
  background-color: $colors-1a3769;

  .main-header {
    .fssa-logo {
      height: 90px;
      align-items: center;
    }

    .contents {
      display: flex;

      .site-name {
        align-self: center;
        color: $colors-ffffff;
        font-size: 1.25em;
        text-decoration: none;
      }
    }
  }
}

.bds-container {
  max-width: 1140px;
  min-width: 450px;
}

.main-sub-header-container {
  background-color: $colors-ffffff;

  .main-sub-header {
    .bds-graphic {
      height: 90px;
      align-items: center;
    }

    .contents {
      color: $colors-000000;
      font-size: 1rem;
    }
  }
}

.page-footer {
  background-color: $colors-000000;

  .footer-contents {
    font-size: 0.8rem;
    color: $colors-ffffff;

    .contact-menu {
      text-align: center;
      margin-bottom: .5rem;
    }
    .footer-menu {
      display:flex;
      flex-direction: row;
      justify-content:center;
      align-items:center;
      margin-bottom: .5rem;
      a {
        margin-right:10px;
      }
    }
  }
}

.main-card {
  border: 2px solid $colors-d3d3d3;
  border-radius: 20px;
  background-color: $colors-ffffff;
  padding: 2rem 2rem 2.5rem 2rem;
}

.bds-maintenance-banner {
  background-color: $colors-ffe920;
}

.bds-maintenance-heading {
  font-size: 1rem;
  font-weight: $font-weight-bold;
}

.bds-maintenance-message {
  font-size: 1rem;
  font-weight: $font-weight-bold;
}

.bds-session-expired-message {
  font-size: 1rem;
  font-weight: $font-weight-bold;
}

.bds-page-not-found-message {
  font-size: 1rem;
  font-weight: $font-weight-bold;
}

.bds-application-submitted {
  font-size: 1rem;
}

.bds-redirection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f0f0f0;
  display:flex;
  align-items:center;
  justify-content: center;
  font-size: 1.5rem;
}

.bds-auto-expire-session-banner, .bds-internal-server-error-banner {
  position:sticky;
  top:0;
  left:0;
  right:0;
  background-color: $colors-ffe920;
  z-index: 2000;

  .heading {
    font-size: 1rem;
    font-weight: $font-weight-bold;
  }

  .message {
    font-size: 1rem;
    font-weight: $font-weight-bold;
  }
}

.bds-resume-session-button {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  float: right;
  margin-bottom: 1rem;
}

.bds-document-upload-wrapper { 
  border-style: solid;
  border-width: thin;
  border-color: $colors-888888; 
  border-radius: 5px; 
  padding: 5px; 
  background-color: $colors-cccccc;
}

.bds-file-preview-container {
  background-color: $colors-cccccc;
}

.confirmation-of-diagnosis {
  background: url('../../../src/assets/images/confirmation-of-diagnosis.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-width:1%;
  height:50vh;
}

.authorization-for-disclosure {
  background: url('../../../src/assets/images/authorization-for-disclosure.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-width:1%;
  height:50vh;
}

.bds-dashboard-section-heading {
  font-size: 1.25rem;
  font-weight: $font-weight-bold;
}


ul.no-bullets {
  list-style-type: none;
}

ul.no-indent {
  padding-left: 0;
}

.bds-error {
  color: #ff0000;
  
}