

.bds-summary-section-heading {
    font-size: 1.25rem;
    font-weight: $font-weight-medium;
}

.bds-summary-label {
    font-size: 1rem;
    font-weight: $font-weight-bold;
}