.bds-loading-overlay {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000AA;
  vertical-align: middle;

  .bds-loading-overlay-trap {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.bds-loading-overlay-hidden {
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
    left: auto !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
  }

  .spinner-border {
    color: #ffffff;
    border-width: 6px;
    height: 60px;
    width: 60px;
  }

  .spinner-positioning {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.bds-loading-overlay-disable-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}