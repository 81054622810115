.bds-form-field-indicator {
    margin-left: 10px;
    font-size: .8rem;
    text-align: right;

    &.dirty {
        color: #ffcc00;
    }

    &.error {
        color: #e90000;
    }

    &.saving {
        color: #888888;
    }

    &.saved {
        color: #0f9a00c8;
    }
}

.bds-form-field-indicator-error {
    border: 1px solid #ff0000;
    background-color: #ff000016;
}