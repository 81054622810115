
.signature-heading {
    font-size: 1.75rem;
    font-weight: $font-weight-medium;
}

.signature-description {
    font-size: 1rem;
}

.signature-label {
    font-size: 1rem;
}